import { gql } from "@apollo/client";
import {
  Intent,
  PopoverInteractionKind,
  TooltipProps,
} from "@blueprintjs/core";
import { HexType, SpecialVersionType } from "@hex/common";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { HexAnchorButton, HexTooltip } from "../../hex-components/index.js";
import { ORG_ID } from "../../orgs.js";
import { Routes } from "../../route/routes.js";
import { useDarkTheme } from "../../theme/themes/darkTheme.js";
import {
  ArrowTopRightIcon,
  ComponentIcon,
  ProjectIcon,
} from "../icons/CustomIcons.js";

import { ProjectToolTipFragment } from "./ProjectTooltip.generated.js";

gql`
  fragment ProjectToolTipFragment on Hex {
    id
    hexType
    currentDraft {
      id
      title
      description
    }
    lastPublishedVersion {
      id
    }
  }
`;

export interface ProjectTooltipProps {
  data: ProjectToolTipFragment;
  children?: ReactNode;
  tooltipRenderTarget?: TooltipProps["renderTarget"];
  toolTipTargetTagName?: TooltipProps["targetTagName"];
}

export const ProjectTooltip: React.ComponentType<ProjectTooltipProps> =
  React.memo(function ProjectTooltip({
    children,
    data,
    toolTipTargetTagName,
    tooltipRenderTarget,
  }: ProjectTooltipProps) {
    const darkTheme = useDarkTheme();

    const {
      currentDraft: { description, title },
      hexType,
      id: hexId,
      lastPublishedVersion,
    } = data;

    const route =
      hexType === HexType.COMPONENT
        ? Routes.COMPONENT
        : lastPublishedVersion != null
          ? Routes.APP
          : Routes.LOGIC;

    const version =
      lastPublishedVersion != null
        ? SpecialVersionType.LAST_PUBLISHED
        : SpecialVersionType.DRAFT;

    return (
      <HexTooltip
        content={
          <Container>
            <Meta>
              <div
                css={`
                  display: flex;
                  gap: 4px;
                `}
              >
                {hexType === HexType.PROJECT ? (
                  <ProjectIcon color={darkTheme.intent.PRIMARY} />
                ) : (
                  <ComponentIcon color={darkTheme.intent.PRIMARY} />
                )}
                {title.length > 30 ? title.substring(0, 30) + "..." : title}
              </div>
              <HexAnchorButton
                extraSmall={true}
                href={Routes.href(ORG_ID, true, route, {
                  hexId,
                  version,
                })}
                intent={Intent.PRIMARY}
                minimal={true}
                rightIcon={<ArrowTopRightIcon />}
                target="_black"
                text="View"
              />
            </Meta>
            {description && (
              <Description>
                {description.length > 240
                  ? description.substring(0, 240) + "..."
                  : description}
              </Description>
            )}
          </Container>
        }
        interactionKind={PopoverInteractionKind.HOVER}
        maxWidthOverride={280}
        renderTarget={tooltipRenderTarget}
        targetTagName={toolTipTargetTagName}
      >
        {children}
      </HexTooltip>
    );
  });

const Container = styled.div`
  display: flex;
  padding: 3px 0;
  flex-direction: column;
  gap: 2px;

  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const Meta = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const Description = styled.div`
  margin: 4px 0 0 4px;
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  color: ${({ theme }) => theme.fontColor.MUTED};
`;
